html {
  height: 100%;
  width: 100%;
  min-width: 1078px;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  width: 100%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ugly fix, but creating a custom theme seems like quite an overkill.. https://github.com/schiehll/react-alert/issues/113 */
#__react-alert__ * {
  text-transform: initial;
}